@import './vars.scss';

@media (max-width: $mobileScreenMaxSizeLowerSide) or (max-height: $mobileScreenMaxSizeLowerSide) {
    :root {
        touch-action: pan-x pan-y;
        height: 100% 
    }
}

/** make page fully fit screen on mobile without needing to slide to hide the searchbar and reveal the bottom of the page */
html, body {
	@media (min-width: calc($mobileScreenMaxSizeLowerSide + 1px)) and (min-height: calc($mobileScreenMaxSizeLowerSide + 1px)) {
        --vh: 1vh !important
    }    
    height: calc(var(--vh, 1vh) * 100) !important;
    min-height: calc(var(--vh, 1vh) * 100) !important;
}

html {
    background: radial-gradient(rgba(var(--vp-color-primary-rgb), 0.5), var(--vp-color-ternary-alt) 80%);
    @media(min-width: 1100px) {
        background: radial-gradient(rgba(var(--vp-color-primary-rgb), 0.85), var(--vp-color-ternary-alt) 80%);
        background-size: 100% 200%;
        background-repeat: no-repeat;
        background-position-y: 100%;
    }
    @media(min-width: 1300px) {
        background: radial-gradient(var(--vp-color-primary), var(--vp-color-ternary-alt) 85%);
        background-size: 100% 200%;
        background-repeat: no-repeat;
        background-position-y: 100%;
    }
    @media(min-width: 1600px) {
        background: radial-gradient(var(--vp-color-primary), var(--vp-color-ternary-alt) 90%);
        background-size: 100% 200%;
        background-repeat: no-repeat;
        background-position-y: 100%;
    }
    @media(min-width: 1900px) {
        background: radial-gradient(var(--vp-color-primary), var(--vp-color-ternary-alt) 95%);
        background-size: 100% 200%;
        background-repeat: no-repeat;
        background-position-y: 100%;
    }
}

body {
    margin: 0;

    background: url('../images/login_background.jpg'), radial-gradient(rgba(var(--vp-color-primary-rgb), 0.5), var(--vp-color-ternary-alt) 80%);
    background-position: bottom;
    background-size: 1100px 1650px;
    background-repeat: no-repeat;
    @media(min-width: 1100px) {
        mask: radial-gradient(circle, black 50%, transparent 90%);
    }
    @media(min-width: 1300px) {
        mask: radial-gradient(circle, black 50%, transparent 70%);
    }
    @media(min-width: 1600px) {
        mask: radial-gradient(circle, black 50%, transparent 60%);
    }
    @media(min-width: 1900px) {
        mask: radial-gradient(circle, black 45%, transparent 55%);
    }
}

.page-security {
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(var(--vp-color-primary-rgb), 0.5), rgba(var(--vp-color-primary-rgb), 0.5) 50%, rgba(var(--vp-color-ternary-alt-rgb), 0.85)), radial-gradient(rgba(var(--vp-color-ternary-alt-rgb), 0.8125),rgba(var(--vp-color-ternary-alt-rgb), 0.8125) 50%,var(--vp-color-ternary-alt));
    background-size: 100% 200%;
    background-repeat: no-repeat;
    background-position-y: 100%;
    backdrop-filter: blur(2px);
    height: calc(var(--vh, 1vh) * 100) !important;
    color: white;
    position: relative;
    background-size: 100% 200%;
    background-repeat: no-repeat;
    background-position-y: 100%;
    * {
        box-sizing: content-box;
    }

    .security-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 65vh;
        padding-top: 8vh;
    }

    .icon-container {
        text-align: center;
        img:first-child {
            width: 225px;
            margin: 20px 0;
        }
        .logo-text {
            font-size: large;
            color: white;
        }
        object:last-child {
            width: 225px;
        }
    }

    #flash-messages {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }


    .content {
        margin: 0 auto;
        max-width: 28rem;
        padding: 50px 80px;
        background-color: var(--vp-color-ternary-alt);
        opacity: 0.9;
        border-radius: 24px;
        width: 400px;
        max-width: calc(100vw - 160px);

        .title {
            font-size: x-large;  padding-bottom: 8%;
            font-weight: bold;
        }

        .alert-danger {
            color: #FF4500;
            margin-bottom: 4%;
        }

        form {
            min-height: 280px;
        }

        .form-widget {
            margin: 18px 0;
            input {
                height: 38px;
                line-height: 38px;
                font-size: small;
                border-radius: 18px;
                border: 1px solid var(--vp-color-secondary);
                background-color: #11121A;
                padding: 5px 20px;
                color: #6D6C94;
                width: -webkit-fill-available;
                width: -moz-available;
                
                &:focus, &:focus-visible {
                    outline: var(--vp-color-secondary-alt) 1px solid;
                }
            }
        }
                
        .form-text {
            font-size: inherit;
            margin: 5px 0;
            text-align: right;
            a {
                color: var(--vp-color-secondary);
                text-decoration: none;
            }
        }

        .checkbox-input {
            margin-bottom: 10px;
            label {
                color: var(--vp-color-secondary);
            }
            input {
                appearance: auto;
                background-color: var(--vp-color-secondary);
                &[id="remember_me"] {
                    margin: 3px 4px;
                    background-color: #FFF;
                }
            }
        }

        .toggle-show-password {
            position: absolute;
            top: 50%;
            right: 0;
            translate: -50% -50%;
            display: flex;
            padding: 5px;
            background: none;
            border: none;
            color: var(--vp-color-secondary);
            font-size: large;
            &:hover {
                cursor: pointer;
            }
        }

        button[type="submit"] {
            border: none;
            padding: 14px;
            width: calc(100% - 28px);
            font-size: large;
            font-weight: bold;
            color: black !important;
        }
        
        @media(max-width: 700px) {
            padding: 20px 30px;
            max-width: calc(100vw - 60px);
            border-radius: 0;
            .form-widget {
                margin: 10px 0;
            }
        }
    }

    footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: var(--vp-color-ternary-alt);
        opacity: 0.6;
        width: 100%;

        .copyright-notes {
            margin-top: auto;
            color: #B9B9B9DD;
            margin-bottom: 25px;
        }
    }

	@media(max-height: 767px) {
        .content {
            .icon-container img:first-child {
                margin: 15px 0;
            }
            .title {
                font-size: 20px;
            }
            form {
                font-size: 14px;
            }
        }
        input[type="text"] {
            height: 30px;
            line-height: 30px;
        }
        button[type="submit"] {
            height: 18px;
        }
        footer {
            .copyright-notes {
                margin-bottom: 5px;
            }
        }
    }
}